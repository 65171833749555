<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="usuario/listar"
		:busca="{
            busca: busca.busca || null,
            iddepartamento: busca.iddepartamento || null,
            idfilial: busca.idfilial || null,
            idgrupo: busca.idgrupo || null,
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idusuario: {nome: 'ID', tipo: permissao('usuario_get') ? 'link' : 'texto', link: '/usuario/get/'},
            erpidusuario: {nome: 'ID CISS', tipo: 'texto'},
            usuario: {nome: 'Usuário', tipo: 'texto'},
            nome: {nome: 'Nome', tipo: 'texto'},
            filial: {nome: 'Filial', tipo: 'objeto', campos: ['filial']},
            grupo: {nome: 'Grupo', tipo: 'objeto', campos: ['grupo']},
            status: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo'},
        }"
	>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="filiais.lista"
				item-text="filial"
				item-value="idfilial"
				label="Filial"
				outlined
				dense
				v-model="busca.idfilial"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="grupos.lista"
				item-text="grupo"
				item-value="idgrupo"
				label="Grupo"
				outlined
				dense
				v-model="busca.idgrupo"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="departamentos.lista"
				item-text="departamento"
				item-value="iddepartamento"
				label="Departamento"
				outlined
				dense
				v-model="busca.iddepartamento"
			></v-select>
		</v-col>
		<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
			<v-sheet class="text-center" height="100%">
				<v-row class="pa-3">
					<v-col cols="12" class="px-3 mb-n8">
						<v-select
							clearable
							:items="filiais.lista"
							item-text="filial"
							item-value="idfilial"
							label="Filial"
							outlined
							dense
							v-model="busca.idfilial"
						></v-select>
					</v-col>
					<v-col cols="12" class="px-3 mb-n8">
						<v-select
							clearable
							:items="grupos.lista"
							item-text="grupo"
							item-value="idgrupo"
							label="Grupo"
							outlined
							dense
							v-model="busca.idgrupo"
						></v-select>
					</v-col>
					<v-col cols="12" class="px-3 mb-n8">
						<v-select
							clearable
							:items="departamentos.lista"
							item-text="departamento"
							item-value="iddepartamento"
							label="Departamento"
							outlined
							dense
							v-model="busca.iddepartamento"
						></v-select>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-col class="px-1">
			<v-text-field
				class="d-flex d-md-none"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				append-outer-icon="mdi-menu"
				@click:append-outer="sheet = !sheet"
			/>
			<v-text-field
				class="d-none d-md-block"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import Table from "../Widgets/Table";

export default {
	name: "UsuarioLista",
	mixins: [mixinFilial],
	components: { Table },
	data: () => ({
		sheet: false,
		keydown: false,
		grupos: {},
		departamentos: {},
		busca: {
			idfilial: 0,
			idgrupo: 0,
			iddepartamento: 0,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit"]),
	},
	methods: {
		listarGrupos() {
			return axios
				.post(`${this.backendUrl}usuario/grupo/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					this.grupos = res.data;
				});
		},
		listarDepartamentos() {
			return axios
				.post(`${this.backendUrl}departamento/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					this.departamentos = res.data;
				});
		},
		async init() {
			await this.listarGrupos();
			await this.listarDepartamentos();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>